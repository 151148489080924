import { Injectable } from "@angular/core";
import { ProfileResolver } from "@app/resolvers/profile.resolver";
import { environment } from "@env/environment";
import Echo from "laravel-echo";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class EchoService {
  echo: Echo;

  constructor(
    private authService: AuthService,
    private profileService: ProfileResolver
  ) {}

  initConnection() {
    this.echo = new Echo({
      broadcaster: "pusher",
      key: environment.notification.pusherKey,
      cluster: "us2",
      authEndpoint: environment.notification.authEndpoint,
      auth: {
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      },
    });
  }

  listenNotifications() {
    return this.echo.private(
      "notification." + this.profileService.profileInfo.id
    );
  }

  listenStatus() {
    return this.echo.private("message." + this.profileService.profileInfo.id);
  }

  listenQB() {
    return this.echo.private(
      "quickbooks." + this.profileService.profileInfo.id
    );
  }

  disconnect() {
    this.echo.disconnect();
  }
}
